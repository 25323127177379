/**
 * Converts the mobx apiQueue error messages to a writable
 * svelte store, which is rendered as Alerts in the Alerts
 * component
 */
import type { ClientUserStore } from 'shared/src';
import type { IReactionDisposer } from 'mobx';
import { autorun } from 'mobx';
import type ApiQueue from 'shared/src/apiClient/apiQueue';
import { writable } from 'svelte/store';
import type { AlertInfo, AlertInfoWithUUID } from '$lib/stores/alerts.store';

export const apiAlertsStore = writable<AlertInfoWithUUID[]>([]);

export function observeApiErrors(clientUserStore: ClientUserStore): IReactionDisposer {
  function getMessages() {
    // Get the messages to show:
    const messages = clientUserStore.api.ApiQueue.messages
      .map(messageToAlert)
      .filter((a): a is AlertInfoWithUUID => !!a);
    apiAlertsStore.update(() => messages);
  }

  const disposer = autorun(() => getMessages());
  return disposer;
}

function messageToAlert(message: ApiQueue['messages'][number]) {
  if (!message.text) return undefined;

  let level: AlertInfo['level'];
  switch (message.status) {
    case 'resolved':
      level = 'success';
      break;
    case 'pending':
      level = 'info';
      break;
    default:
      level = 'failure';
  }

  const retryCallback = 'retry' in message ? message.retry : undefined;
  const cancelCallback = 'cancel' in message ? message.cancel : undefined;

  const alertInfo: AlertInfoWithUUID = {
    level,
    message: message.text,
    dismissable: false,
    retryCallback,
    cancelCallback,
    id: `api-error-${message.id}`,
  };
  return alertInfo;
}
