<script lang="ts">
  import { mdiCheck, mdiExclamationThick, mdiAlert } from '@mdi/js';
  import { store, removeAlert } from '../stores/alerts.store';
  import MaterialIcon from '$lib/MaterialIcon.svelte';
  import Alert from './Alert.svelte';
  import { twMerge } from 'tailwind-merge';
  import { apiAlertsStore } from '$lib/app/apiErrors/apiErrorsStore';

  let clazz: string = '';
  export { clazz as class };

  $: alerts = [...$store, ...$apiAlertsStore].map((info) => {
    let classname: string;
    let icon: string;
    let label: string;
    let iconColor: string;
    if (info.level === 'success') {
      classname = 'bg-green-50 text-green-900';
      icon = mdiCheck;
      iconColor = 'text-green-500';
      label = 'Check';
    } else if (info.level === 'failure') {
      classname = 'bg-red-50 text-red-800';
      icon = mdiAlert;
      iconColor = 'text-red';
      label = 'Warning';
    } else {
      // level === info
      classname = 'bg-yellow-100 text-yellow-900';
      icon = mdiExclamationThick;
      iconColor = 'text-blue-800';
      label = 'Success';
    }

    return {
      ...info,
      class: classname,
      icon,
      iconColor,
      label,
    };
  });
</script>

<div class={twMerge('sticky top-0 left-0 right-0 bottom-auto z-50 shadow-2xl', clazz)}>
  {#each alerts as info (info.id)}
    <Alert
      dismissable={info.dismissable}
      class={info.class}
      on:dismiss={() => removeAlert(info.id)}
    >
      <svelte:fragment slot="icon">
        <MaterialIcon
          path={info.icon}
          label={info.label}
          size="1em"
          class="pr-4 {info.iconColor}"
        />
      </svelte:fragment>
      {info.message}
      {#if info.retryCallback}
        <button class="a" on:click={info.retryCallback}>Retry</button>
      {/if}
      {#if info.cancelCallback}
        <button
          class="a"
          on:click={() => {
            if (info.cancelCallback) {
              info.cancelCallback();
            }
          }}>Cancel</button
        >
      {/if}
    </Alert>
  {/each}
</div>
