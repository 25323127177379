<script lang="ts">
  import MaterialIcon from '$lib/MaterialIcon.svelte';
  import { mdiClose } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import { slide } from 'svelte/transition';
  let klazz: string = '';
  export { klazz as class };
  export let dismissable: boolean = false;

  const dispatch = createEventDispatcher();
</script>

<div class={klazz} transition:slide|local>
  <div class="margins">
    <div class="px-4 py-2">
      <div class="flex">
        <slot name="icon" />
        <div class="text-sm flex-grow" aria-live="polite">
          <slot />
        </div>
        {#if dismissable}
          <button
            class="float-right"
            on:click={() => {
              dispatch('dismiss');
            }}
          >
            <MaterialIcon path={mdiClose} /><span class="sr-only">Close message</span>
          </button>
        {/if}
      </div>
    </div>
  </div>
</div>
